import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunk from 'redux-thunk';
import rootReducer from '../reducers/index';
import gameplayReducer from '../reducers/gameplay';

const combinedReducers = combineReducers({
  root: rootReducer,
  gameplay: gameplayReducer
});

let store;
if (process.env.NODE_ENV === 'development') {
  store = createStore(combinedReducers, (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)(
    applyMiddleware(thunk)
  ));
} else {
  store = createStore(
    combinedReducers,
    applyMiddleware(thunk)
  );
}

export default store;
