import React, { Component } from 'react';
import Content from './components/Content';
import { GenericMessage } from './components/common/GenericMessage';
import { SERVER_DOWN } from './strings/constants';
import { GetVersion } from './utils/request';
import { connect } from 'react-redux';
import {
  isAPIServerConnected
} from './redux/actions/index';

import "./App.css";

class App extends Component {
  state = {
    loaded: false
  }

  componentDidMount() {
    this.getVersion()
  }

  showConfirm = (msg) => {
    // eslint-disable-next-line no-restricted-globals
    var r = confirm(msg);

    if (r === true) {
      window.location.reload();
    }
  }

  async getVersion() {
    if (!window.navigator.onLine) {
      this.showConfirm('You are not connected to the Internet.');
      return;
    }

    await GetVersion((connected, data) => {
      this.props.isAPIServerConnected(connected);

      if (!connected) {
        this.setState({
          loaded: true
        });

        return;
      }

      if (data.web !== this.props.version) {
        this.showConfirm('An update is available! Please reload the page.');
        return;
      }

      this.setState({
        loaded: true
      });
    })
  }

  render() {
    if (!this.props.isAPIConnected) {
      return (
        <GenericMessage
          icon="cloud-server"
          message={SERVER_DOWN}
          size={150}
        />
      )
    }

    return <Content />
  }
}

const mapStateToProps = state => {
  return {
    isAPIConnected: state.root.isAPIConnected,
    version: state.root.version
  }
};

const mapDispatchToProps = dispatch => {
  return {
    isAPIServerConnected: connected => dispatch(isAPIServerConnected(connected))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
