import React from 'react';
import { connect } from 'react-redux';
import { Table } from "reactstrap";
import ProfilePic from '../../../common/ProfilePic';
import LobbyGameVoting from '../voting/LobbyGameVoting';
import './LobbyScores.css';

function LobbyScores(props) {
  const { user, room } = props;

  if (!user) return null;

  const maxScores = 10;
  const scoreCount = room.scores.length;

  const myPlayer = room.players.find(player => player.slackID === user.id);
  const myScore = room.scores.find(score => score.id === myPlayer.id);
  const myIndex = room.scores.findIndex(score => score.id === myPlayer.id);

  const scoreRows = room.scores.slice(0, maxScores).map((score, index) =>
    <tr key={index}>
      <td className="fit">
        <ProfilePic
          profileSrc={room.players.find(p => p.id === score.id) ? room.players.find(p => p.id === score.id).profileURL : null}
          size="sm"
        />
      </td>
      <td>
        {score.name}
      </td>
      <td className="text-right">
        {score.displayValue}
      </td>
    </tr>
  );

  return (
    <>
      
      <LobbyGameVoting />

      <h5 className="mb-2">Scores</h5>
      <Table className="text-left" id="scores-table">
        <tbody>
          {scoreRows}
        </tbody>
      </Table>

      { (scoreCount > maxScores) ? (
        <Table id="my-score-table" className="mt-2">
          <tbody>
            <tr>
              <td className="text-center font-weight-bold" colspan="3">
                YOU RANKED {myIndex} OF {scoreCount}
              </td>
            </tr>
            <tr>
              <td className="fit">
                <ProfilePic
                  profileSrc={room.players.find(p => p.id === myScore.id) ? room.players.find(p => p.id === myScore.id).profileURL : null}
                  size="sm"
                />
              </td>
              <td className="text-left">
                {myScore.name}
              </td>
              <td className="text-right">
                {myScore.displayValue}
              </td>
            </tr>
          </tbody>
        </Table>
      ) : null }

    </>
  );
}

const mapStateToProps = state => {
  return {
    user: state.root.user,
    room: state.gameplay.room
  }
};

export default connect(mapStateToProps, null)(LobbyScores);
