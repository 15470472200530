import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  updateVote
} from '../../../../redux/actions/index';
import GameGIF from '../../../common/GameGIF';
import './LobbyGameVoting.css';

function LobbyGameVoting(props) {
  const { room, updateVote } = props;

  const [spaceVoting, setSpaceVoting] = useState(false);
  const [showVoting, setShowVoting] = useState(false);

  useEffect(() => {
    let t = setTimeout(() => {
      setSpaceVoting(true);
    }, 30);
    let s = setTimeout(() => {
      setShowVoting(true);
    }, 350);
    return () => {
      clearTimeout(s);
      clearTimeout(t);
    }
  }, []);

  const handleVote = (index) => {
    if (room.voted) return;
    // This will tell the server to register the vote for this game
    updateVote({ id: room.id, index: index })
  }

  //for testing...
  /*room.votingGames = [
    {
      bucketKey: 'https://wiblits-game-assets.s3.us-east-2.amazonaws.com/3/game.gif',
      gameId: 3,
      voteCount: 1,
      emoji: '🔡'
    },
    {
      bucketKey: 'https://wiblits-game-assets.s3.us-east-2.amazonaws.com/4/game.gif',
      gameId: 4,
      voteCount: 13,
      emoji: '🏔️'
    },
    {
      bucketKey: 'https://wiblits-game-assets.s3.us-east-2.amazonaws.com/5/game.gif',
      gameId: 5,
      voteCount: 46,
      emoji: '🤸'
    },
    {
      bucketKey: 'https://wiblits-game-assets.s3.us-east-2.amazonaws.com/6/game.gif',
      gameId: 6,
      voteCount: 100,
      emoji: '🎈'
    }
  ]*/

  if(!room.votingGames || room.votingGames.length < 1) return null;

  const gameBlocks = room.votingGames.slice(0, 4).map((game, index) =>
    <div className="voting-game-block">
      <div className="mb-2">
        {game.emoji}
      </div>
      <div className="voting-clickable-gif">
        <GameGIF
          bucketKey={game.bucketKey}
          size="sm"
          border={game.gameId === 4 /*room.votedGame*/}
          action={() => handleVote(index + 1)}
        />
      </div>
      <div className="mt-2 small">
        {game.voteCount}
      </div>
    </div>
  );

  return (
    <div className={showVoting ? 'voting-container-visible' : spaceVoting ? 'voting-container-space' : 'voting-container-start'}>
      <div>{room.roundText}</div>
      <div className="small">Vote for the next game!</div>
      <div className="mt-3 mb-4">
        {gameBlocks}
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    room: state.gameplay.room
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateVote: payload => dispatch(updateVote(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LobbyGameVoting);
