import * as Request from '../../utils/request';

import {
  SET_USER,
  UPDATE_USER,
  API_SERVER_CONNECTED,
  SET_HEADERS,
  SET_ROOM_TOKEN,
  SET_GAME,
  START_GAME,
  UPDATE_ROOM,
  ADD_PLAYER,
  UPDATE_VOTES,
  ADD_SCORE
} from '../constants/action-types';

import {
  startReqActionType,
  failReqActionType,
  successReqActionType,
} from './helpers';

import history from '../../utils/history';

export const setUser = user => ({ type: SET_USER, payload: user });
export const isAPIServerConnected = connected => ({ type: API_SERVER_CONNECTED, payload: connected });
export const setHeaders = headers => ({ type: SET_HEADERS, payload: headers });
export const setRoomToken = token => ({ type: SET_ROOM_TOKEN, payload: token });

const _apiRequest = async options => {
  const { dispatch, actionBase, payload, method, path, returnPayloadOnSuccess, callback } = options;

  dispatch({ type: startReqActionType(actionBase) });
  let response;
  try {
    // TODO: Remove this eventually
    if (actionBase === 'EDIT_LOCATION' && payload.name === 'ERROR') {
      throw new Error('foo bar error throw');
    }

    response = await Request[method](path, payload);
  } catch (err) {
    dispatch({ type: failReqActionType(actionBase), payload: err });
    return Promise.reject();
  }

  if (response.error && options.notificationOnError) {
    dispatch({ type: failReqActionType(actionBase), payload: {message: response.error } });
  } else {
    dispatch({
      type: successReqActionType(actionBase),
      payload: returnPayloadOnSuccess ? payload : response.data,
      ...options.metaDispatch
    });
  }

  if (callback) {
    callback(response.data);
  }

  return Promise.resolve(response);
};

export const updateUser = payload => async dispatch => {
  const response = await Request.Put('user', payload)

  if (response.error) {
    window.showError(response.error);
    return;
  }

  dispatch({
    type: UPDATE_USER,
    payload: payload,
  });
};

export const addPlayer = payload => async dispatch => {
  dispatch({
    type: ADD_PLAYER,
    payload: payload,
  });
};


// GAMEPLAY
export const startGame = payload => async dispatch => {
  // Send request to server to start the round
  const response = await Request.Put('room', payload)

  if (response.error) {
    console.error(response.error);
    return;
  }

  /*
    Probably don't need to do anything with the response, just wait
    for live updates to start
  */
};

export const postScore = payload => async dispatch => {
  dispatch({
    type: UPDATE_ROOM,
    payload: { showGame: false },
  });

  // Send request to server to start the round
  const response = await Request.Post('score', payload)

  if (response.error) {
    console.error(response.error);
    return;
  }
};

export const setGame = game => ({ type: SET_GAME, payload: game });
export const updateRoom = updates => ({ type: UPDATE_ROOM, payload: updates });
export const updateVotes = updates => ({ type: UPDATE_VOTES, payload: updates });
export const addScore = payload => ({ type: ADD_SCORE, payload: payload });

export const updateVote = payload => async dispatch => {
  dispatch({
    type: UPDATE_ROOM,
    payload: { voted: payload.index },
  });

  // Send request to server to vote for the game
  const response = await Request.Post('vote', payload)

  if (response.error) {
    console.error(response.error);
    return;
  }
};
