import React from 'react';
import { connect } from 'react-redux';
import ResultsPodium from './ResultsPodium';
import PerformanceSummary from './PerformanceSummary';
import LobbyGameVoting from '../voting/LobbyGameVoting';
import './LobbyResults.css';

function LobbyResults(props) {
  const { room } = props;

  return(
    <>
      <LobbyGameVoting />
      <div>
        {room.results ? <ResultsPodium results={room.results} /> : null}
        {room.performanceSummary ? <PerformanceSummary performanceSummary={room.performanceSummary} /> : null}
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    room: state.gameplay.room
  }
};

export default connect(mapStateToProps, null)(LobbyResults);
