import { AMQPConfig } from '../utils/configs';
import { Deployment } from '../utils/deployment';
import { v4 as uuidv4 } from 'uuid';
import store from "../redux/store/index";
import {
  setGame,
  updateRoom,
  addPlayer,
  updateVotes,
  addScore
} from '../redux/actions/index';

const AMQP_URL = Deployment.amqp;

var pubClient = null;
var subClient = null;
var channelSubscription = null;

var Rabbit = {}

function Init(roomID) {
  Rabbit.sessionID = uuidv4()

  subClient = new window.StompJs.Client({
      brokerURL: AMQP_URL,
      connectHeaders: AMQPConfig,
      debug: function (str) {
        console.log(str);
      }
    });

  subClient.onConnect = function(frame) {
    SubscribeToRoom(roomID)
  };

  subClient.activate();
}

function SendMessage(data, queue) {
  data.sessionID = Rabbit.sessionID

  pubClient.send(queue, { 'content-type': 'text/plain' }, JSON.stringify(data));
}

async function SubscribeToRoom(roomID) {
  const queueID = `wib_${Deployment.environment}_${roomID}`

  const queueString = `${queueID}_${Rabbit.sessionID}`

  channelSubscription = await subClient.subscribe(`/exchange/amq.direct/${queueID}`, async function(d) {
    try {
      var data = JSON.parse(d.body)

      if (data.duration) {
        // New game
        handleGame(data)
      }

      if (data.scores) {
        // A round ended
        handleScores(data.scores)
      }

      if (data.calculating) {
        store.dispatch(updateRoom({ calculating: true }));
      }

      if (data.results) {
        // Final results podium
        handleResults(data.results)
      }

      if (data.boarding) {
        // A player left or joined
        store.dispatch(addPlayer(data));
      }

      if (data.games) {
        // Update the voting games
        store.dispatch(updateRoom({
          votingGames: data.games,
          voted: null,
          break: false,
          roundText: data.roundText,
          results: null
        }));
      }

      // This is an array of integers (vote counts) corresponding to the votingGames
      if (data.votes) {
        // Update the voting games with the new vote counts
        store.dispatch(updateVotes(data.votes));
      }

      if (data.play) {
        // Hide the instructions overlay and start the game
        store.dispatch(updateRoom({ instructions: false }));

        if (window.startGame) window.startGame();
      }

      if (data.score) {
        // A player's score was received
        store.dispatch(addScore(data));
      }
    } catch(e) {
      console.log(e);
    }
  }, { 'id': queueID, 'x-queue-name': queueString } )
}

function handleGame(data) {
  store.dispatch(setGame(data));
  store.dispatch(updateRoom({
    instructions: true,
    showGame: true,
    votingGames: null,
    voted: null,
    scores: []
  }));
}

function handleScores(data) {
  store.dispatch(updateRoom({
    scores: data,
    roundFinished: true
  }));
}

function handleResults(data) {
  store.dispatch(updateRoom({
    results: data,
    break: true,
    calculating: false,
    roundText: null,
    scores: []
  }));
}

function Disconnect() {
  if (channelSubscription) {
    channelSubscription = null;
    Rabbit.queueString = null;
  }

  if (subClient) {
    subClient.deactivate()
  }
}

export { Init, Rabbit, SendMessage, SubscribeToRoom, Disconnect }
