import {
  ADD_PLAYER,
  REMOVE_PLAYER,
  START_GAME,
  SET_GAME,
  LEAVE_ROOM,
  CLOSE_ROOM,
  SET_SCORES,
  UPDATE_ROOM,
  UPDATE_VOTES,
  ADD_SCORE
} from '../constants/action-types';

/*
  0 = Joining the room
  1 = Waiting for round to start
  2 = Voting
  3 = Playing
  4 = Waiting for scores
  5 = Scores
  6 = Results (Podium)
*/

/*
  room.votingGames is an array of game objects, e.g.

  [{
    id: 1,
    name: 'Alphabet Squares',
    bucketKey: '...'
    count: 3
  }]
*/

const initialState = {
  game: null,
  room: {
    id: null,
    players: [],
    scores: [],
    votingGames: [],
    voted: null,
    instructions: false,
    showGame: false,
    roundFinished: false,
    calculating: false,
    break: true,
    roundText: null
  },
  score: null
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    // TODO: Handle all actions
    case SET_GAME:
      return { ...state, game: action.payload };

    // You can update any property here by passing in a status, scores, etc.
    case UPDATE_ROOM:
      return { ...state, room: { ...state.room, ...action.payload }};
    case UPDATE_VOTES:
      var games = state.room.votingGames

      for (var i = 0; i < action.payload.length; i++) {
        games[i].voteCount = action.payload[i]
      }

      return { ...state, room: { ...state.room, votingGames: games }};
    case ADD_PLAYER:
      // If they joined, add them to the players array, otherwise remove them
      if (action.payload.joined) {
        var found = false;
        for (var i = 0; i < state.room.players.length; i++) {
          const player = state.room.players[i];
          if (player.id === action.payload.id) {
            found = true;
            break;
          }
        }

        if (found) {
          return {...state}
        }

        return { ...state, room: { ...state.room, players: [...state.room.players, action.payload] }};
      } else {
        const index = (player) => player.id === action.payload.id;
        const players = state.room.players
        players.splice(state.room.players.findIndex(index), 1)

        return { ...state, room: { ...state.room, players: players } };
      }
    case ADD_SCORE:
      return { ...state, room: { ...state.room, scores: [...state.room.scores, action.payload] } };
    default:
      return state;
  }
};

export default rootReducer;
