import React from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  startGame
} from '../../../redux/actions/index';
import LobbyGameVoting from './voting/LobbyGameVoting';
import LobbyResults from './results/LobbyResults';
import LobbyPlayers from './table/LobbyPlayers';
import LobbyScores from './table/LobbyScores';
import LobbyWaiting from './waiting/LobbyWaiting';
import { Container, Row, Col, Button } from "reactstrap";
import './RoomLobby.css';

function RoomLobby(props) {
  let dispatch = useDispatch();

  const { user, room, game } = props;

  if (!user) return null;

  const handleStartGame = () => {
    dispatch(startGame({ id: room.id, start: true }));
  }

  const renderLobby = () => {
    if (!game) {
      return <LobbyPlayers />;
    }

    if (room.calculating) {
      return <div>Getting everyone's final score...</div>
    }

    if (room.votingGames) {
      return <LobbyGameVoting />;
    }

    if (room.results) {
      return <LobbyResults />;
    }

    if (room.roundFinished) {
      return <LobbyScores />;
    }

    return <LobbyWaiting />;
  }

  return (
    <Container className="full-screen">
      <Row className="justify-content-center pt-5 pb-5">
        <Col className="text-center" xs="12" sm="12" md="8" lg="6" xl="6">
          {room.ownerID === user.id && room.break && (
            <Button
              color="primary"
              onClick={handleStartGame}
              size="lg"
              className="mb-5"
            >
              Start Game
            </Button>
          )}
          {renderLobby()}
          </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    user: state.root.user,
    room: state.gameplay.room,
    game: state.gameplay.game
  }
};

export default connect(mapStateToProps)(RoomLobby);
