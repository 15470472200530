import React from 'react';
import './GameGIF.css';

export default function GameGIF(props) {
  const { bucketKey, size, border, action } = props;

  return (
    <img 
      className={'game-gif-' + size + (border ? ' game-gif-border-' + size : '')} 
      src={bucketKey} 
      onClick={action}
    />
  );
}
