var environment = 'dev';

//var host = 'https://localhost:443/v1/';
var host = 'http://localhost:8080/';
var amqp = 'ws://localhost:15674/ws';

const version = "Wiblits Web Version 0.1.0";

getEnvironment();

function getEnvironment() {
  switch (environment) {
    case 'local':
      break;
    case 'dev':
      host = 'https://dev.wiblits.com/';
      amqp = 'wss://red-guppy.rmq.cloudamqp.com/ws';
      break
    case 'prod':
      host = 'https://api.wiblits.com/';
      break
    default:
      break
  }
}

const Deployment = {
  environment: environment,
  host: host,
  version: version,
  amqp: amqp
}

export { Deployment }
