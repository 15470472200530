import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as rabbit from '../../controllers/rabbit';
import { Deployment } from '../../utils/deployment';
import { useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { connect } from 'react-redux';
import Gameplay from '../gameplay/Gameplay';
import RoomLobby from './lobby/RoomLobby';
//import RoomOverlay from './header/RoomHeader';
import * as Cookies from 'js-cookie';
import history from '../../utils/history';
import { GetUrlParam } from '../../utils/utils';

import {
  updateRoom,
  setUser
} from '../../redux/actions/index';

function Room(props) {
  let dispatch = useDispatch();

  const { token } = useParams();
  const { room } = props;

  const userID = GetUrlParam('slack_id', '');
  const roomID = jwtDecode(token).room;

  window.onbeforeunload = function() {
    leaveRoom();

    return null;
  }

  useEffect(() => {
    joinRoom();
  }, []);

  const leaveRoom = () => {
    fetch(`${Deployment.host}slack/join`, {
      method: 'post',
      body: JSON.stringify({
        roomID: roomID,
        slackID: userID,
        joined: false
      }),
    });
  }

  const joinRoom = async () => {
    Cookies.set('x-user-id', userID);
    dispatch(setUser({ id: userID }));

    const data = {
      roomID: roomID,
      slackID: userID,
      joined: true
    }

    const req = await fetch(`${Deployment.host}slack/join`, {
      method: 'post',
      body: JSON.stringify(data)
    })

    const response = await req.json();

    if (response.error) {
      alert(response.error);
      console.error(response.error);
      return;
    }

    //set the css theme variables
    /*Object.keys(response.room.theme).forEach(t => {
      document.documentElement.style.setProperty(t, response.room.theme[t]);
    });*/

    dispatch(updateRoom(response.data));

    // Start live updates
    rabbit.Init(roomID)
  }

  if (room.showGame) {
    return <Gameplay />
  }

  return (
    <RoomLobby
      leaveRoom={() => {
        leaveRoom();

        // Stop live updates
        rabbit.Disconnect();

        // Transition somewhere...
        history.push('/');
      }}
    />
  )
}

const mapStateToProps = state => {
  return {
    room: state.gameplay.room
  }
};

export default connect(mapStateToProps)(Room);
