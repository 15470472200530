import React from 'react';
import GameGIF from '../../common/GameGIF';
import { Container, Row, Col } from "reactstrap";
import './GameInstructions.css';

export default function GameInstructions(props) {

  const { game } = props;

  const touchCapable = 'ontouchstart' in window || window.DocumentTouch && document instanceof window.DocumentTouch || navigator.maxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0;

  return(
    <div className="game-instructions-container">
      <Container className="full-screen" style={{background: "var(--dark-lighter)"}}>
        <Row className="align-items-center justify-content-center h-100">
          <Col xs="10" sm="10" md="8" lg="6" xl="6" className="text-center">
            <h1 className="game-instructions-emoji mb-4">{game.emoji}</h1>
            {/*<div className="game-instructions-title mb-3">{game.name}</div>*/}
            <div className="game-instructions-gif">
              <GameGIF bucketKey={game.bucketKey} size="lg" border={true}/>
            </div>
            <h4 className="game-instructions-text mt-5">{game.instructions}</h4>
          </Col>
        </Row>
      </Container>
    </div>
  );

}
