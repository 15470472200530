import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  postScore
} from '../../../redux/actions/index';
import GameOverlay from './GameOverlay';
import './Game.css';

export default function Game(props) {
  const { game } = props;
  const [hasScored, setHasScored] = useState(false);
  const [score, setScore] = useState(null);
  const dispatch = useDispatch();

  window.webkit = {
    messageHandlers: {
      loadComplete: {
        postMessage: function(message) {
          console.log(message);
        }
      },
      gameMessage: {
        postMessage: function(obj) {
          if (obj.type === 'roundFinished') {
            handlePostScore();
          } else if (obj.type === 'timeElapsed') {
            setScore(obj.message);
          } else if (obj.type === 'pointScored') {
            setScore(obj.message);
          }
        }
      }
    }
  }

  const handlePostScore = () => {
    if (hasScored) return;
    setHasScored(true);

    dispatch(postScore({ id: game.roomGameID, ...window.getScore() }))

    if (window.game) {
      window.game.destroy();
      window.game = null;
    }
  }

  /*
    If the user hasn't posted a score yet when the time is up, call getScore()
  */
  useEffect(() => {
    let t = setTimeout(() => {
      handlePostScore();
    }, 5000 + (game.duration*1000));

    return () => (
      clearTimeout(t)
    )
  }, [hasScored]);

  useEffect(() => {
    var runGame = new Function(game.code + ' window.game = game; window.getScore = getScore; window.startGame = startGame;');
    runGame();
  }, []);

  return (
    <>
      <GameOverlay score={score}/>
      <div id="game-container"/>
    </>

  )
}
