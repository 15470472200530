export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const API_SERVER_CONNECTED = "API_SERVER_CONNECTED";
export const SET_HEADERS = "SET_HEADERS";
export const SET_ROOM_TOKEN = "SET_ROOM_TOKEN";

// GAMEPLAY ACTIONS

// Player joined
export const ADD_PLAYER = "ADD_PLAYER";

// Player left
export const REMOVE_PLAYER = "REMOVE_PLAYER";

// Owner started the round
export const START_GAME = "START_GAME";

/*
  Game object would include game details (name, image, etc.) as well as
  the game code (including configuration) for a particular round.

  Can be set to NULL after playing a round. If this is null, render the
  Gameplay component, otherwise render the RoomLobby.
*/
export const SET_GAME = "SET_GAME";

// Post a score
export const POST_SCORE = "POST_SCORE";

// Owner wants to post the rankings, scores etc. back to Slack
export const POST_SCORES = "POST_SCORES";

// Leave the room
export const LEAVE_ROOM = "LEAVE_ROOM";

// Owner closed the room
export const CLOSE_ROOM = "CLOSE_ROOM";

// The scores for the round were received
export const SET_SCORES = "SET_SCORES";

// E.g. set the new roomId
export const UPDATE_ROOM = "UPDATE_ROOM";

// Received new votes
export const UPDATE_VOTES = "UPDATE_VOTES";

// Received a player's score
export const ADD_SCORE = "ADD_SCORE";
