import {
  SET_USER,
  API_SERVER_CONNECTED,
  SET_HEADERS,
  SET_ROOM_TOKEN
} from '../constants/action-types';

const initialState = {
  user: null,
  isAPIConnected: true,
  version: require('../../../package.json').version,
  headers: new Headers()
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case API_SERVER_CONNECTED:
      return { ...state, isAPIConnected: action.payload };
    case SET_USER:
      return { ...state, user: action.payload };
    case SET_HEADERS:
      return { ...state, headers: action.payload };
    case SET_ROOM_TOKEN:
      return { ...state, roomToken: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
