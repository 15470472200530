import React from 'react';
import './GameOverlay.css';

export default function GameOverlay(props) {

  const { score } = props;
  
  return (
    <h4 className="game-overlay-container">{score}</h4>
  );
}