import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Game from './game/Game';
import GameInstructions from './instructions/GameInstructions';

function Gameplay(props) {
  const { game, room } = props;

  return (
    <>
      { room.instructions && <GameInstructions game={game} /> }
      <Game game={game}/>
    </>
  );
}

const mapStateToProps = state => {
  return {
    game: state.gameplay.game,
    room: state.gameplay.room
  }
};

export default connect(mapStateToProps, null)(Gameplay);
