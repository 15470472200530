import React from 'react';
import { Router, Route } from 'react-router-dom';
import history from '../utils/history';
import Room from './rooms/Room';
import Lander from './Lander';

export default function Content(props) {
  return (
    <Router history={history}>
      <Route path="/" exact component={Lander} />
      <Route path="/room/:token" exact component={Room} />
    </Router>
  );
}
