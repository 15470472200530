import React from 'react';
import { connect } from 'react-redux';
import { Table } from "reactstrap";
import ProfilePic from '../../../common/ProfilePic';
import LobbyGameVoting from '../voting/LobbyGameVoting';
import './LobbyPlayers.css';

function LobbyPlayers(props) {
  const { user, room } = props;

  if (!user) return null;
  if (room.players.length === 0) return null;

  const myPlayer = room.players.find(player => player.slackID === user.id);
  const filteredPlayers = room.players.filter(player => player.slackID !== user.id);

  const myRow =
    <tr>
      <td className="fit">
        <ProfilePic
          profileSrc={myPlayer.profileURL}
          size="sm"
        />
      </td>
      <td>
        {myPlayer.name}
      </td>
    </tr>

  const playerRows = filteredPlayers.map((player, index) =>
    <tr key={index}>
      <td className="fit">
        <ProfilePic
          profileSrc={player.profileURL}
          size="sm"
        />
      </td>
      <td>
        {player.name}
      </td>
    </tr>
  );

  return (
    <>
      <LobbyGameVoting />
      <h5 className="mb-2">Players</h5>
      <Table className="text-left" id="players-table">
        <tbody>
          {myRow}
          {playerRows}
        </tbody>
      </Table>
    </>
  );
}

const mapStateToProps = state => {
  return {
    user: state.root.user,
    room: state.gameplay.room
  }
};

export default connect(mapStateToProps, null)(LobbyPlayers);
