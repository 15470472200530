import React from 'react';
import './ProfilePic.css';

export default function ProfilePic(props) {
  const { profileSrc, size } = props;

  return (
    <img
      src={profileSrc}
      alt="profile-pic"
      className={'profile-pic-' + size}
    />
  );
}
