import React from 'react';
import { connect } from 'react-redux';
import GameGIF from '../../../common/GameGIF';
import ProfilePic from '../../../common/ProfilePic';
import { Row, Col } from "reactstrap";
import './LobbyWaiting.css';

function LobbyWaiting(props) {
  const { room, game } = props;

  const players = room.players.slice(0, 24)//.concat(room.players.slice(0, 24)).concat(room.players.slice(0, 24)).concat(room.players.slice(0, 24)).concat(room.players.slice(0, 24)).concat(room.players.slice(0, 24)).concat(room.players.slice(0, 24)).concat(room.players.slice(0, 24)).concat(room.players.slice(0, 24)).concat(room.players.slice(0, 24));

  const playerBlocks = players.map((p, index) =>
    <Col className="pt-3 pb-3 text-center" xs="3" sm="3" md="2" lg="2" xl="2">
      <div className="lobby-waiting-player-container">
        <div className={room.scores && room.scores.length > 0 && !room.scores.find(s => p.id === s.id) ? 'lobby-waiting-player player-dead' : 'lobby-waiting-player player-alive bounce bounce-' + Math.floor(Math.random()*9) + ' duration-' + Math.floor(Math.random()*9)}>
          <ProfilePic
            profileSrc={p.profileURL}
            size="md"
          />
        </div>
      </div>
    </Col>
  );

  return (
    <div>
      <div>
        <div className="voting-game-block">
          <div class="mb-2">
            {game.emoji}
          </div>
          <div className="voting-clickable-gif">
            <GameGIF 
              bucketKey={game.bucketKey} 
              size="sm" 
              border={true} 
            />
          </div>
        </div>
      </div>
      <div className="small mt-4 mb-4">Waiting for everyone's scores...</div>
      <Row className="pl-3 pr-3">
        {playerBlocks}
      </Row>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    room: state.gameplay.room,
    game: state.gameplay.game
  }
};

export default connect(mapStateToProps, null)(LobbyWaiting);
