import { Deployment } from './deployment';
import store from '../redux/store/index';

const host = Deployment.host

async function Get(path) {
  return await requestHelper(path, null, 'get');
}

async function GetVersion(cb) {
  await fetch(`${host}v1/version`, {
    method: 'get'
  }).then(async function(response) {
    const resp = await response.json();

    cb(true, resp.data)
  }).catch(function(error) {
    cb(false);
  })
}

async function Post(path, data) {
  return await requestHelper(path, data, 'post');
}

async function Put(path, data) {
  return await requestHelper(path, data, 'put');
}

async function Delete(path, data) {
  return await requestHelper(path, data, 'delete');
}

async function requestHelper(path, data, method) {
  const url = `${host}v1/${path}`

  const req = await fetch(url, {
    method: method,
    body: data ? JSON.stringify(data) : null,
    headers: new Headers({
      'x-user-id': `${store.getState().root.user.id}`
    })
  })

  return await req.json();
}

export {
  Get,
  GetVersion,
  Post,
  Put,
  Delete
}
