import React from 'react';
import { connect } from 'react-redux';

export default function LobbyResults(props) {
  const { room } = props;

  return(
    <div>Results Podium</div>
  );
}
